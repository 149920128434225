/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    span: "span",
    a: "a",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Time and time again Yelowsoft has come up with various updates which make our product better than before. At Yelowsoft, we are always looking to improve our products as per our clients’ requirement. Continuing the same tradition, we came up with yet another product update in the form of driver payment settlement."), "\n", React.createElement("div", null, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 49.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdS5rSGMR//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEAAQUCX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABgQAAIDAAAAAAAAAAAAAAAAABAhETFB/9oACAEBAAE/IZts6f/aAAwDAQACAAMAAAAQpD//xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAwEBPxBIv//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCxr//EABoQAAMAAwEAAAAAAAAAAAAAAAABERAhQTH/2gAIAQEAAT8Qa9I8Witxw2Qcx//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-introduces-automated-main\"\n        title=\"\"\n        data-src=\"/static/1ae40a83646be3daeafc12a2304eb7da/a24e6/yelowsoft-introduces-automated-main.jpg\"\n        data-srcset=\"/static/1ae40a83646be3daeafc12a2304eb7da/a6b4f/yelowsoft-introduces-automated-main.jpg 200w,\n/static/1ae40a83646be3daeafc12a2304eb7da/a6407/yelowsoft-introduces-automated-main.jpg 400w,\n/static/1ae40a83646be3daeafc12a2304eb7da/a24e6/yelowsoft-introduces-automated-main.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "So, the first question is what exactly is driver payment settlement? To answer this it’s important to understand that the ", React.createElement(_components.a, {
    href: "/blog/automated-taxi-solution-for-your-taxi-business"
  }, "taxi business"), " is very complex in nature due to the presence of a large number of drivers, riders, vehicles, and many other components."), "\n", React.createElement(_components.p, null, "Even more complex is the management of the billing, invoice, and payment of all drivers. To accomplish this herculean task, ", React.createElement(_components.a, {
    href: "/blog/partnership-of-taxi-companies-with-hotels-bars-and-restaurants"
  }, "taxi companies"), " require the help of the automated driver settlement solution that can track the payments, generate an invoice, and settle all kind of payments with utmost accuracy. To achieve these many taxi businesses integrate third-party billing systems into their ", React.createElement(_components.a, {
    href: "/taxi-dispatch-software"
  }, "taxi dispatch solution"), "."), "\n", React.createElement(_components.p, null, "Many of the Yelowsoft’s clients were also facing problems when it came to billing and payment settlements. Considering all this, Yelowsoft decided to have its own in-built driver payment settlement feature. Now, let’s see how it works."), "\n", React.createElement(_components.p, null, "To understand the working of driver payment settlement, it’s important to understand the working of driver wallet."), "\n", React.createElement(_components.h2, {
    id: "driver-wallet"
  }, "Driver wallet"), "\n", React.createElement(_components.p, null, "Driver wallet shouldn’t be confused with that of any payment wallet or ", React.createElement(_components.a, {
    href: "https://www.digipay.guru/blog/peer-to-peer-payment-app-development",
    target: "_blank",
    rel: "nofollow"
  }, "peer-to-peer wallet app"), ". The main objective of the driver wallet is to make driver payment and commission collection simple and accurate."), "\n", React.createElement(_components.p, null, "In this, a driver has to top-up his wallet, only then he becomes eligible for accepting rides. Now every time driver finishes a ride the commission gets automatically deducted from the wallet. Moreover, the amount in the wallet is bifurcated into minimum balance and withdrawable amount."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/blog/working-of-wallet-system-important-features"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgME/9oADAMBAAIQAxAAAAGzUoYBlf8A/8QAGRABAAIDAAAAAAAAAAAAAAAAAgABAxEy/9oACAEBAAEFAhe0uTiFz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAgEBPwFyW//EABkQAAIDAQAAAAAAAAAAAAAAAAERAAIQIf/aAAgBAQAGPwIvC6vs/8QAGBABAQEBAQAAAAAAAAAAAAAAAREAITH/2gAIAQEAAT8hU3g3CmsyVFd//9oADAMBAAIAAwAAABB7/wD/xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EI1T/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxDZJb//xAAbEAACAgMBAAAAAAAAAAAAAAABEQAhQVFhcf/aAAgBAQABPxBXxAVXZqzqIymYIeT/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-introduces-automated-cta1\"\n        title=\"\"\n        data-src=\"/static/19cde26ab733a99076653db32a663562/a24e6/yelowsoft-introduces-automated-cta1.jpg\"\n        data-srcset=\"/static/19cde26ab733a99076653db32a663562/a6b4f/yelowsoft-introduces-automated-cta1.jpg 200w,\n/static/19cde26ab733a99076653db32a663562/a6407/yelowsoft-introduces-automated-cta1.jpg 400w,\n/static/19cde26ab733a99076653db32a663562/a24e6/yelowsoft-introduces-automated-cta1.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "The minimum balance is defined by the taxi company admin and the driver should maintain that amount otherwise he won’t eligible to accept any ride requests. Similarly, the withdrawable amount is the amount that the driver can withdraw from his wallet at any time."), "\n", React.createElement(_components.p, null, "Let’s take an example to understand this better."), "\n", React.createElement(_components.p, null, "Suppose a driver has top-up his driver wallet with $1000. The minimum balance is $100. So, $900 will be the withdrawable amount. Now for the sake of simplicity, let’s assume that the driver completed 10 rides worth $500 and the commission for each ride was $5. So, the total commission of the rides will be $50 which will be deducted from the driver’s wallet balance, i.e. $1000-$50= $9950."), "\n", React.createElement(_components.h2, {
    id: "pay-in"
  }, "Pay in"), "\n", React.createElement(_components.p, null, "Pay in is referred to the total amount of money that a driver needs to pay after considering various factors like commission, tax, penalties, incentives, and many others at the end of a cycle."), "\n", React.createElement(_components.h2, {
    id: "payout"
  }, "Payout"), "\n", React.createElement(_components.p, null, "The total amount of money to be paid to the driver after considering all the above factors at the end of the cycle is called payout."), "\n", React.createElement(_components.p, null, "With driver payment settlement, the taxi admin track all the payments, assign deductions to the settlements, export the file that has summary and details of settlements, etc."), "\n", React.createElement(_components.p, null, "It’s also important to note that all the cashless and card payments made by rider directly go to the taxi company. These payments are then made to the drivers after 7 days, 15 days, or so on. That’s the reason why many drivers don’t prefer cashless ride requests."), "\n", React.createElement(_components.p, null, "You can take advantage of this and use the driver payment settlement to regulate the cash flow to the driver. Let’s see how?"), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/blog/complete-guide-on-automated-driver-billings-payouts"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAawFnDNf/8QAFxABAQEBAAAAAAAAAAAAAAAAAgEhAP/aAAgBAQABBQKbXgiXf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPwERf//EABcQAQEBAQAAAAAAAAAAAAAAAAEAMlH/2gAIAQEABj8CZTlpv//EABoQAAICAwAAAAAAAAAAAAAAAAABEVEhMUH/2gAIAQEAAT8h2HDLjlM0NzdZ/9oADAMBAAIAAwAAABDwL//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8QekR//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxBr3a3/xAAXEAEBAQEAAAAAAAAAAAAAAAARASEA/9oACAEBAAE/EJBVKwpylCoLpyIdTOf/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-introduces-automated-cta2\"\n        title=\"\"\n        data-src=\"/static/ab4a725c409600cd2faaae1e6082f866/a24e6/yelowsoft-introduces-automated-cta2.jpg\"\n        data-srcset=\"/static/ab4a725c409600cd2faaae1e6082f866/a6b4f/yelowsoft-introduces-automated-cta2.jpg 200w,\n/static/ab4a725c409600cd2faaae1e6082f866/a6407/yelowsoft-introduces-automated-cta2.jpg 400w,\n/static/ab4a725c409600cd2faaae1e6082f866/a24e6/yelowsoft-introduces-automated-cta2.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))), "\n", React.createElement(_components.p, null, "Let’s say you have identified three drivers A, B, and C that have a huge amount of withdrawable money in their wallet and they want to withdraw it at the end of the month. However, you’re in urgent need of money."), "\n", React.createElement(_components.p, null, "In such case, you can ensure that all the drivers A, B, and C only gets rides with cashless payments so that your company gets all the fare which can be later adjusted with the outstanding amount of those drivers in the payouts."), "\n", React.createElement(_components.h2, {
    id: "processing"
  }, "Processing"), "\n", React.createElement(_components.p, null, "All the payout amount is first put in processing or in progress status for the time period starting from the accounts department sending the amount to the respective driver’s bank account and ending at the time when it actually gets reflected in the driver’s account."), "\n", React.createElement(_components.p, null, "Now, why was it needed? Let’s take an example to understand this better."), "\n", React.createElement(_components.p, null, "Let’s say a driver has $1200 in his wallet and he wants to withdraw $1000 from it. So, you assign your account department to do the same. The accounting department sends that amount to the driver’s bank account and it gets reflected in the driver’s account."), "\n", React.createElement(_components.p, null, "However, the entire process takes 2-3 days and in that period the driver completed a bunch of rides and his wallet balance now reads the amount of $800. However, the amount to be withdrawn was $1000, which is more than the current balance."), "\n", React.createElement(_components.p, null, "To avoid such a scenario, we came with the processing ", React.createElement(_components.a, {
    href: "/features"
  }, "feature"), " which freezes the amount until it is paid out to the driver. So, when a said amount is in processing, the driver can’t use that amount. The moment the amount is processed and is reflected in the driver’s bank account, the processing amount is zeroed."), "\n", React.createElement(_components.p, null, "That was all about this product update. There are plenty of other product updates lined up. So, if you want to know about them then keep reading this space."), "\n", React.createElement("div", null, React.createElement(_components.a, {
    href: "/contact"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 20%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAEABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAAB0bNIBH//xAAXEAEBAQEAAAAAAAAAAAAAAAACASIh/9oACAEBAAEFAh1OZEz/AP/EABURAQEAAAAAAAAAAAAAAAAAAAAB/9oACAEDAQE/AYj/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAEBAAY/ApU1/8QAGBABAAMBAAAAAAAAAAAAAAAAAQARIXH/2gAIAQEAAT8hSFVIS9UpKjp7P//aAAwDAQACAAMAAAAQj/8A/8QAFREBAQAAAAAAAAAAAAAAAAAAADH/2gAIAQMBAT8QhD//xAAWEQADAAAAAAAAAAAAAAAAAAABEDH/2gAIAQIBAT8QNX//xAAaEAEBAQADAQAAAAAAAAAAAAABEQAhUWFx/9oACAEBAAE/EApiw65ysYBj6ZAP1Lv/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"yelowsoft-introduces-automated-cta3\"\n        title=\"\"\n        data-src=\"/static/bfdfe65e2671d39dafb5b7d3ff93e2e1/a24e6/yelowsoft-introduces-automated-cta3.jpg\"\n        data-srcset=\"/static/bfdfe65e2671d39dafb5b7d3ff93e2e1/a6b4f/yelowsoft-introduces-automated-cta3.jpg 200w,\n/static/bfdfe65e2671d39dafb5b7d3ff93e2e1/a6407/yelowsoft-introduces-automated-cta3.jpg 400w,\n/static/bfdfe65e2671d39dafb5b7d3ff93e2e1/a24e6/yelowsoft-introduces-automated-cta3.jpg 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
